import './App.css';
import { Box, Button, Footer, Form, FormField, Grommet, Header, Heading, Image, MaskedInput, Page, PageContent } from 'grommet';
import { useState } from 'react';
import { hpe } from 'grommet-theme-hpe';
import axios from 'axios';

const defaultValue = {
  email: ''
};

const getUrl = (email) => {
  return (localStorage.getItem('LOGIN_URL') || process.env.REACT_APP_LOGIN_URL || 'https://login.okta.com') + "?username=" + email;
}

export const App = () => {
  const [value, setValue] = useState(defaultValue);
  return (
    <Grommet full theme={hpe}>
      <Header background="light-3" pad="small">
        <Image src='/logo.png' />
      </Header>
      <Page background="background-front" kind="narrow" height="calc(100% - 96px)">
        <PageContent align="center" justify="center" gap='large' height="100%">
          <Heading margin="none" size='xxsmall'>IdP Proxy Login</Heading>
          <Box width="medium">
            <Form
              value={value}
              onChange={(nextValue, { touched }) => {
                console.log('Change', nextValue, touched);
                setValue(nextValue);
              }}
              onReset={() => setValue(defaultValue)}
              onSubmit={(event) => {
                console.log('Submit', event.value, event.touched);
                axios.get('/test')
                //window.location.assign(getUrl(event.value.email));
              }
              }
            >
              <FormField label="Email" htmlFor="email" name="email" required>
                <MaskedInput
                  id="email"
                  name="email"
                  mask={[
                    { regexp: /^[\w\-_.]+$/, placeholder: 'user' },
                    { fixed: '@' },
                    { regexp: /^[\w]+$/, placeholder: 'greenlakedev' },
                    { fixed: '.' },
                    { regexp: /^[\w]+$/, placeholder: 'org' },
                  ]}
                />
              </FormField>
              <Box direction="row" justify="between" margin={{ top: 'medium' }}>
                <Button type="submit" label="Login" primary />
              </Box>
            </Form>
          </Box>
        </PageContent>
      </Page>
      <Footer pad="small"></Footer>
    </Grommet>
  );
}

export default App;
